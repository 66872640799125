import moment from 'moment';
import {sample, samplex} from '../pages/Form';

export const Template4 = ({data = sample}) => (
  <html>
    <head>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="anonymous"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
        rel="stylesheet"
        crossOrigin="anonymous"
      />
      <script src="https://cdn.tailwindcss.com"></script>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css"
        crossOrigin="anonymous"
      />
      <meta name="viewport" content="width=100%" />
      <style
        dangerouslySetInnerHTML={{
          __html: `
              body {
                font-family: 'Poppins', sans-serif;
                background-color: #fff;
                
              }
              html, body {
                width:  210mm;
                min-height: 297mm;
              }

              a {
                text-decoration: underline;
                
              }

              @page{
                size: A4;
                margin-left: 0px;
                margin-right: 0px;
                margin-top: 0px;
                margin-bottom: 0px;
              }

              ::-webkit-scrollbar {
                width: 0;
              }

              

    `,
        }}></style>
    </head>
    <body>
      <div className="w-full min-h-[100vh] flex flex-col items-center bg-white">
        <div className="flex flex-col w-full items-center py-6">
          <div className="bg-[#ffdecf] w-full flex flex-col items-center">
            <span className="bg-white px-8 py-1 text-[40px] font-semibold">
              {data.name}
            </span>
          </div>
          <span className="text-gray-400 mt-2 text-lg">{data.title}</span>
        </div>
        <div className="w-full flex min-h-[100vh] text-gray-800 gap-12 ">
          {/* Left */}
          {data.links.length === 0 && data.skills.length === 0 ? null : (
            <div
              className="flex flex-col min-h-[100vh] w-[250px] gap-7 text-gray-50 p-8 pt-0"
              style={{borderRight: '1.5px solid #e8e8e8'}}>
              {(data.email || data.location || data.phone) && (
                <div className="flex flex-col gap-3">
                  <span
                    className="text-lg mb-1 font-normal text-gray-800 "
                    style={{borderBottom: '1px solid #e8e8e8'}}>
                    CONTACT
                  </span>
                  {data.email && (
                    <div className="flex items-center text-[#717171]">
                      <i className="fa-solid fa-envelope  min-w-[25px]"></i>
                      <span className="text-xs ">
                        <a href={`mailto:${data.email}`}>{data.email}</a>
                      </span>
                    </div>
                  )}
                  {data.phone && (
                    <div className="flex items-center text-[#717171]">
                      <i className="fa-solid fa-phone min-w-[25px]"></i>
                      <span className="text-xs">
                        <a href={`tel:${data.phone}`}>{data.phone}</a>
                      </span>
                    </div>
                  )}
                  {data.location && (
                    <div className="flex items-center text-[#717171]">
                      <i className="fa-solid fa-location-dot min-w-[25px]"></i>
                      <span className="text-xs">{data.location}</span>
                    </div>
                  )}
                </div>
              )}
              {data.links?.length > 0 && (
                <div className="flex flex-col gap-2">
                  <span
                    className="text-lg mb-1 font-normal text-gray-800 "
                    style={{borderBottom: '1px solid #e8e8e8'}}>
                    LINKS
                  </span>
                  {data.links.map((item, index) => (
                    <a href={item.url} className="text-xs text-[#717171]" target="_blank">
                      {item.name}
                    </a>
                  ))}
                </div>
              )}

              {data.skills?.length > 0 && (
                <div className="flex flex-col gap-2">
                  <span
                    className="text-lg mb-1 font-normal text-gray-800 "
                    style={{borderBottom: '1px solid #e8e8e8'}}>
                    SKILLS
                  </span>
                  {data.skills.map((item, index) => (
                    <span className="text-xs text-[#717171] ">{item.name}</span>
                  ))}
                </div>
              )}
            </div>
          )}

          {/* Right */}
          <div className="flex flex-1 flex-col p-4 min-h-full pt-0">
            {data.summary && (
              <div className="flex flex-col gap-2">
                <span
                  className="text-lg mb-1 font-medium text-gray-800 "
                  style={{borderBottom: '1px solid #e8e8e8'}}>
                  PROFILE
                </span>
                <span
                  className="text-gray-600 text-sm"
                  dangerouslySetInnerHTML={{__html: data.summary}}
                />
              </div>
            )}

            {data.history.length > 0 && (
              <div className="flex flex-col mt-8 gap-3">
                <span
                  className="text-lg mb-1 font-medium text-gray-800 "
                  style={{borderBottom: '1px solid #e8e8e8'}}>
                  WORK EXPERIENCE
                </span>
                {data.history.map((item, index) => (
                  <div className="flex flex-col gap-2">
                    <span className="text-base font-semibold">
                      {[item.title, item.employer].filter(Boolean).join(', ')}
                    </span>
                    <span className="text-xs text-gray-400">
                      {[
                        `${moment(item.startDate).format(
                          'YYYY/MM/DD',
                        )} - ${moment(item.endDate).format('YYYY/MM/DD')}`,
                        item.location,
                      ].join(',')}
                    </span>
                    <span
                      className="text-gray-600 text-sm whitespace-pre-line"
                      dangerouslySetInnerHTML={{__html: item.description}}
                    />
                  </div>
                ))}
              </div>
            )}

            {data.educations?.length > 0 && (
              <div className="flex flex-col mt-8 gap-3">
                <span
                  className="text-lg mb-1 font-medium text-gray-800 "
                  style={{borderBottom: '1px solid #e8e8e8'}}>
                  EDUCATION
                </span>
                {data.educations.map((item, index) => (
                  <div className="flex flex-col gap-2">
                    <span className="text-lg font-semibold">
                      {[item.degree, item.school].join(', ')}
                    </span>
                    <span className="text-xs text-gray-400">
                      {[
                        `${moment(item.startDate).format(
                          'YYYY/MM/DD',
                        )} - ${moment(item.endDate).format('YYYY/MM/DD')}`,
                        item.location,
                      ].join(',')}
                    </span>
                    <span
                      className="text-gray-600 text-sm whitespace-pre-line"
                      dangerouslySetInnerHTML={{__html: item.description}}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </body>
  </html>
);
