import React from 'react';
import {Platform, SafeAreaView, StatusBar} from 'react-native';
import 'react-native-match-media-polyfill'
import Navigator from './navigator';
import {Provider} from 'react-redux';
import {store} from './slices/store';
import './utils/mixpanel'


function App(): JSX.Element {
  return (
    <Provider store={store}>
      <SafeAreaView style={{flex: 1,}}>
        <StatusBar barStyle={'light-content'} backgroundColor="#3b82f6" />
        <Navigator />
      </SafeAreaView>
    </Provider>
  );
}

export default App;
