import TextField from '@mui/material/TextField';
import {useFormik} from 'formik';
import {useEffect} from 'react';
import moment from 'moment';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import Remove from '@mui/icons-material/Delete';
import Add from '@mui/icons-material/Add';
import School from '@mui/icons-material/School';
import Work from '@mui/icons-material/Work';
import Person from '@mui/icons-material/Person2';
import Badge from '@mui/icons-material/Badge';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Link from '@mui/icons-material/Link';
import {Editor} from '../components/Editor';
import { Button, Modal } from 'antd';
import { useMediaQuery } from 'react-responsive';

export const initial = {
  id: Math.random().toString(),
  name: '',
  title: '',
  email: '',
  phone: '',
  location: '',
  summary: ``,
  history: [{}],
  educations: [],

  skills: [],
  links: [],
};

export const samplex = {
  name: 'John Doe',
  title: 'Software Engineer (DEMO)',
  email: 'johndoe@example.com',
  phone: '+1234567890',
  location: 'United States',
  summary:
    'I am a skilled software engineer with a strong focus on front-end development. Over the past few years, I have been building web applications using React/Redux and mobile apps with React Native. Additionally, I have extensive experience in creating REST and GraphQL APIs in NodeJS.',
  history: [
    {
      title: 'Software Engineer',
      employer: 'TechCo',
      startDate: moment().subtract(2, 'years').toISOString(),
      endDate: moment(),
      location: 'New York',
      description:
        'TechCo is a leading tech company that develops cutting-edge software solutions. During my time at TechCo, I contributed to various projects, including designing and implementing new features for their flagship product. I also worked on optimizing performance and resolving critical issues.\n\nWebsite: https://techco.com',
    },
  ],
  educations: [
    {
      school: 'ABC University',
      degree: 'Bachelor of Science in Computer Science',
      startDate: moment().subtract(2, 'years').toISOString(),
      endDate: moment(),
      location: 'New York',
      description:
        'At ABC University, I pursued a degree in Computer Science, where I gained a solid foundation in software development, data structures, and algorithms. I actively participated in coding competitions and collaborated on various projects to enhance my skills.',
    },
  ],
  skills: [
    {
      name: 'React',
      level: 'Expert',
    },
    {
      name: 'TypeScript',
      level: 'Advanced',
    },
    {
      name: 'Node.js',
      level: 'Intermediate',
    },
  ],
  links: [
    {
      name: 'GitHub',
      url: 'https://github.com/johndoe',
    },
    {
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/in/johndoe',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/johndoe',
    },
  ],
};

export const sample = {
  name: 'Alireza Rezania',
  title: 'Front-End Developer',
  email: 'alirezarzna@gmail.com',
  phone: '+989370097918',
  location: 'Turkey',
  summary: `I am a full stack developer with a heavy focus on the Front-End.
    Over the past five years, I have developed web apps with React/Redux
    as well as mobile apps with React Native. Have great understanding
    of back-end technologies and experience in developing REST and
    GraphQL APIs in NodeJS.
    `,
  history: [
    {
      title: 'Web Developer',
      employer: 'Clash Dome',
      startDate: moment().subtract(2, 'years').toISOString(),
      endDate: moment(),
      location: 'Barcelon',
      description: `Clash Dome is a blockchain game platform based on Wax network. Players can play against each others and claim tokens.

        My role: I worked on user dashboard and added new sections like friend requests, Duels, Tournamets, Drating apartments and the landing page and many other contributions.
        
        https://clashdome.io
        `,
    },
  ],
  educations: [
    {
      school: 'Gonabad University',
      degree: 'Bachelor',
      startDate: moment().subtract(2, 'years').toISOString(),
      endDate: moment(),
      location: 'Barcelon',
      description:
        'lorem ipsum dolor sit amet, consectetur adip non proident sed lorem ipsum dolor sit amet, consectetur adip non proident sed',
    },
  ],

  skills: [
    {
      name: 'React',
      level: 4,
    },
    {
      name: 'Typescript',
      level: 3,
    },
  ],

  links: [
    {
      name: 'Github',
      url: 'https://github.com/rezania',
    },
    {
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/in/rezania',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/rezania',
    },
  ],
};

const Form = ({initialForm = samplex}) => {
  const form = useFormik({
    initialValues: initialForm,
    onSubmit: values => {},
    validateOnChange: false,
  });
  const isMobile = useMediaQuery({
    query: '(max-width: 600px)',
  });

  const sendMessageToNative = (name, msg) => {
    (window['ReactNativeWebView'] || window.top)?.postMessage(
      JSON.stringify({name, payload: msg})
    , '*');
  };

  useEffect(() => {
    sendMessageToNative('form', form.values);

    saveToLocalStorage(form.values);


  }, [form.values]);

  const saveToLocalStorage = form => {
    const resumes = localStorage.getItem('resumes');
    if (resumes) {
      const resumesArray = JSON.parse(resumes);
      resumesArray.map(res => res.id).indexOf(form.id) === -1
        ? resumesArray.push(form)
        : (resumesArray[resumesArray.map(res => res.id).indexOf(form.id)] =
            form);

      localStorage.setItem('resumes', JSON.stringify(resumesArray));
    } else {
      localStorage.setItem('resumes', JSON.stringify([form]));
    }
  };


  return (
    <div className="p-3 py-4 gap-8 flex flex-col pt-6">
      <div className="flex flex-col gap-4">
        <TextField
          name="name"
          label="Name"
          value={form.values.name}
          onChange={form.handleChange}
          className="border-[1px] border-gray-700 px-2 rounded w-full"
        />
        <TextField
          name="title"
          label="Job Title"
          value={form.values.title}
          onChange={form.handleChange}
          size="medium"
          className="border-[1px] border-gray-700 px-2 rounded w-full"
        />{' '}
        <TextField
          name="email"
          label="Email"
          value={form.values.email}
          onChange={form.handleChange}
          size="medium"
          className="border-[1px] border-gray-700 px-2 rounded w-full"
        />
        <TextField
          name="phone"
          label="Phone"
          value={form.values.phone}
          onChange={form.handleChange}
          size="medium"
          className="border-[1px] border-gray-700 px-2 rounded w-full"
        />
        <TextField
          name="location"
          label="Location"
          value={form.values.location}
          onChange={form.handleChange}
          size="medium"
          className="border-[1px] border-gray-700 px-2 rounded w-full"
        />
      </div>

      <div className="flex flex-col gap-2">
        <span className="text-gray-800 font-medium mb-[-4px] flex items-center gap-2">
          <Person className="text-gray-800" />
          Professional Summary
        </span>
        <span className="text-sm text-gray-400">
          Write a brief description of your professional background
        </span>
        <Editor
          value={form.values.summary}
          onChange={e => form.setFieldValue('summary', e.target.value)}
        />
      </div>

      <div className="gap-3 flex flex-col">
        <b className="text-gray-800 font-medium mb-[-4px] flex items-center gap-2">
          <Work className="text-gray-800" /> Employment History
        </b>
        <span className="text-sm text-gray-400">
          Highlight your applicable experience from the last decade using bullet
          points to emphasize achievements.
        </span>

        {form.values.history.map((employment, i) => (
          <div key={employment.id} className="flex flex-col gap-4 shadow-lg rounded overflow-hidden">
            <div className="flex-1 min-h-[35px] bg-slate-200 flex items-center px-1 pl-3">
              {
                <span>
                  {[employment.title, employment.employer]
                    .filter(Boolean)
                    .join(', ')}
                </span>
              }
              <Remove
                className="ml-auto text-gray-500 cursor-pointer"
                onClick={() =>
                  form.setFieldValue(
                    `history`,
                    form.values.history.filter((_, idx) => idx !== i),
                  )
                }
              />
              <ArrowUpward
                className="ml-3 text-gray-500 cursor-pointer"
                onClick={() =>
                  form.setFieldValue(
                    'history',
                    moveArrayItem(
                      form.values.history,
                      form.values.history.indexOf(employment),
                      'up',
                    ),
                  )
                }
              />
              <ArrowDownward
                className="ml-3 text-gray-500 cursor-pointer"
                onClick={() =>
                  form.setFieldValue(
                    'history',
                    moveArrayItem(
                      form.values.history,
                      form.values.history.indexOf(employment),
                      'down',
                    ),
                  )
                }
              />
            </div>
            <div className="flex flex-col p-3 gap-3">
              <TextField
                name={`history.${i}.title`}
                label="Job Title"
                value={employment.title || ''}
                onChange={form.handleChange}
                size="medium"
                className="border-[1px] border-gray-700 px-2 rounded w-full"
              />
              <TextField
                name={`history.${i}.employer`}
                label="Employer"
                value={employment.employer || ''}
                onChange={form.handleChange}
                size="medium"
                className="border-[1px] border-gray-700 px-2 rounded w-full"
              />
              <TextField
                name={`history.${i}.location`}
                label="Location"
                value={employment.location || ''}
                onChange={form.handleChange}
                size="medium"
                className="border-[1px] border-gray-700 px-2 rounded w-full"
              />
              <div className="flex items-center gap-4">
                <DatePicker
                  label="Start Date"
                  value={moment(employment.startDate)}
                  onChange={date => {
                    form.setFieldValue(
                      `history.${i}.startDate`,
                      date?.toISOString(),
                    );
                  }}
                  className="border-[1px] border-gray-700 px-2 rounded flex flex-1 min-h-[50px]"
                />
                <DatePicker
                  label="End Date"
                  value={moment(employment.endDate)}
                  onChange={date => {
                    form.setFieldValue(
                      `history.${i}.endDate`,
                      date?.toISOString(),
                    );
                  }}
                  className="border-[1px] border-gray-700 px-2 rounded  flex flex-1 h-[50px]"
                />
              </div>
              <span className="mb-[-8px] text-sm">Description</span>
              <Editor
                value={employment.description}
                onChange={e =>
                  form.setFieldValue(`history.${i}.description`, e.target.value)
                }
              />
            </div>
          </div>
        ))}
        <span
          className="text-blue-500 font-bold flex items-center mt-2 cursor-pointer"
          onClick={() =>
            form.setFieldValue('history', form.values.history.concat([{id: Math.random().toString()}]))
          }>
          <Add /> Add one more employment
        </span>
      </div>

      <div className="gap-3 flex flex-col">
        <span className="text-gray-800 font-medium mb-[-4px] flex items-center gap-2">
          <School className="text-gray-800" />
          Education
        </span>
        <span className="text-sm text-gray-400">
          Write details about your previous experiences
        </span>
        {form.values.educations.map((education, i) => (
          <div key={education.id} className="flex flex-col gap-4  shadow-lg rounded overflow-hidden">
            <div className="flex-1 min-h-[35px] bg-slate-200 flex items-center px-1 pl-3">
              {
                <span>
                  {[education.school, education.degree]
                    .filter(Boolean)
                    .join(', ')}
                </span>
              }
              <Remove
                className="ml-auto text-gray-500 cursor-pointer"
                onClick={() =>
                  form.setFieldValue(
                    `educations`,
                    form.values.educations.filter((_, idx) => idx !== i),
                  )
                }
              />
              <ArrowUpward
                className="ml-3 text-gray-500 cursor-pointer"
                onClick={() =>
                  form.setFieldValue(
                    'educations',
                    moveArrayItem(
                      form.values.educations,
                      form.values.educations.indexOf(education),
                      'up',
                    ),
                  )
                }
              />
              <ArrowDownward
                className="ml-3 text-gray-500 cursor-pointer"
                onClick={() =>
                  form.setFieldValue(
                    'educations',
                    moveArrayItem(
                      form.values.educations,
                      form.values.educations.indexOf(education),
                      'down',
                    ),
                  )
                }
              />
            </div>
            <div className="flex flex-col p-3 gap-3">
              <TextField
                name={`educations.${i}.school`}
                label="School"
                value={education.school}
                onChange={form.handleChange}
                size="medium"
                className="border-[1px] border-gray-700 px-2 rounded w-full"
              />
              <TextField
                name={`educations.${i}.degree`}
                label="Degree"
                value={education.degree}
                onChange={form.handleChange}
                size="medium"
                className="border-[1px] border-gray-700 px-2 rounded w-full"
              />
              <TextField
                name={`educations.${i}.location`}
                label="Location"
                value={education.location}
                onChange={form.handleChange}
                size="medium"
                className="border-[1px] border-gray-700 px-2 rounded w-full"
              />
              <div className="flex items-center gap-4">
                <DatePicker
                  label="Start Date"
                  value={moment(education.startDate)}
                  onChange={date => {
                    form.setFieldValue(
                      `educations.${i}.startDate`,
                      date?.toISOString(),
                    );
                  }}
                  className="border-[1px] border-gray-700 px-2 rounded flex flex-1 min-h-[50px]"
                />
                <DatePicker
                  label="End Date"
                  value={moment(education.endDate)}
                  onChange={date => {
                    form.setFieldValue(
                      `educations.${i}.endDate`,
                      date?.toISOString(),
                    );
                  }}
                  className="border-[1px] border-gray-700 px-2 rounded  flex flex-1 h-[50px]"
                />
              </div>
              <span className="mb-[-8px] text-sm">Description</span>
              <Editor
                value={education.description}
                onChange={e =>
                  form.setFieldValue(
                    `educations.${i}.description`,
                    e.target.value,
                  )
                }
              />
            </div>
          </div>
        ))}
        <span
          className="text-blue-500 font-bold flex items-center mt-2 cursor-pointer"
          onClick={() =>
            form.setFieldValue(
              'educations',
              form.values.educations.concat([{id: Math.random().toString()}]),
            )
          }>
          <Add /> Add one more education
        </span>
      </div>

      <div className="gap-3 flex flex-col">
        <span className="text-gray-800 font-medium mb-[-4px] flex items-center gap-2">
          <Badge className="text-gray-800" />
          Skills
        </span>
        <span className="text-sm text-gray-400">
          Highlight 5 crucial skills that showcase your perfect fit for the job
        </span>
        {form.values.skills.map((skill, i) => (
          <div key={skill.id} className="flex flex-col gap-4 shadow-lg rounded overflow-hidden">
            <div className="flex-1 min-h-[35px] bg-slate-200 flex items-center px-1 pl-3">
              {<span>{skill.name}</span>}
              <Remove
                className="ml-auto text-gray-500 cursor-pointer"
                onClick={() =>
                  form.setFieldValue(
                    `skills`,
                    form.values.skills.filter((_, idx) => idx !== i),
                  )
                }
              />
              <ArrowUpward
                className="ml-3 text-gray-500 cursor-pointer"
                onClick={() =>
                  form.setFieldValue(
                    'skills',
                    moveArrayItem(
                      form.values.skills,
                      form.values.skills.indexOf(skill),
                      'up',
                    ),
                  )
                }
              />
              <ArrowDownward
                className="ml-3 text-gray-500 cursor-pointer"
                onClick={() =>
                  form.setFieldValue(
                    'skills',
                    moveArrayItem(
                      form.values.skills,
                      form.values.skills.indexOf(skill),
                      'down',
                    ),
                  )
                }
              />
            </div>
            <div className="flex flex-col p-3 gap-3">
              <TextField
                name={`skills.${i}.name`}
                label="Skill"
                value={skill.name || ''}
                onChange={form.handleChange}
                size="medium"
                className="border-[1px] border-gray-700 px-2 rounded w-full"
              />
            </div>
          </div>
        ))}
        <span
          className="text-blue-500 font-bold flex items-center mt-2 cursor-pointer"
          onClick={() =>
            form.setFieldValue('skills', form.values.skills.concat([{id: Math.random().toString()}]))
          }>
          <Add /> Add one more skill
        </span>
      </div>

      <div className="gap-3 flex flex-col">
        <span className="text-gray-800  font-medium mb-[-4px] flex items-center gap-2">
          <Link className="text-gray-800" />
          Websites and Social Links
        </span>
        <span className="text-sm text-gray-400">
          You can add links to websites that hiring managers may find helpful,
          such as your portfolio, LinkedIn profile, or personal website
        </span>
        {form.values.links.map((link, i) => (
          <div key={link.id} className="flex flex-col gap-4 shadow-lg rounded overflow-hidden">
            <div className="flex-1 min-h-[35px] bg-slate-200 flex items-center px-1 pl-3">
              {<span>{link.name}</span>}
              <Remove
                className="ml-auto text-gray-500 cursor-pointer"
                onClick={() =>
                  form.setFieldValue(
                    `links`,
                    form.values.links.filter((_, idx) => idx !== i),
                  )
                }
              />
              <ArrowUpward
                className="ml-3 text-gray-500 cursor-pointer"
                onClick={() =>
                  form.setFieldValue(
                    'links',
                    moveArrayItem(
                      form.values.links,
                      form.values.links.indexOf(link),
                      'up',
                    ),
                  )
                }
              />
              <ArrowDownward
                className="ml-3 text-gray-500 cursor-pointer"
                onClick={() =>
                  form.setFieldValue(
                    'links',
                    moveArrayItem(
                      form.values.links,
                      form.values.links.indexOf(link),
                      'down',
                    ),
                  )
                }
              />
            </div>
            <div className="flex flex-col p-3 gap-3">
              <TextField
                name={`links.${i}.name`}
                label="Label"
                value={link.name || ''}
                onChange={form.handleChange}
                size="medium"
                className="border-[1px] border-gray-700 px-2 rounded w-full"
              />
              <TextField
                name={`links.${i}.url`}
                label="Link"
                value={link.url || ''}
                onChange={form.handleChange}
                size="medium"
                className="border-[1px] border-gray-700 px-2 rounded w-full"
              />
            </div>
          </div>
        ))}
        <span
          className="text-blue-500 font-bold flex items-center mt-2 cursor-pointer"
          onClick={() =>
            form.setFieldValue('links', form.values.links.concat([{id: Math.random().toString()}]))
          }>
          <Add /> Add one more link
        </span>
      </div>

      {isMobile && <div
        onClick={() => sendMessageToNative('goToPreview')}
        className="fixed bottom-4 right-4 bg-[#3b82f6] p-4 rounded-lg text-white shadow-lg">
        <span>Preview and Export</span>
      </div>}
    </div>
  );
};

function moveArrayItem(history, index, direction) {
  if (index < 0 || index >= history.length) {
    console.error('Invalid index');
    return history;
  }

  const newIndex = direction === 'up' ? index - 1 : index + 1;

  if (newIndex < 0 || newIndex >= history.length) {
    console.error('Cannot move beyond array bounds');
    return history;
  }

  const newHistory = [...history];
  const [movedItem] = newHistory.splice(index, 1);
  newHistory.splice(newIndex, 0, movedItem);

  return newHistory;
}

export default Form;
