import React from 'react';
import moment from 'moment';

export const Template5 = ({ data }) => (
  <html>
    <head>
      <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap" rel="stylesheet" />
      <script src="https://cdn.tailwindcss.com"></script>
    </head>
    <body className="bg-gray-100">
      <div className="max-w-4xl mx-auto my-10">
        <div className="bg-white shadow-lg overflow-hidden">
          <div className="p-8 bg-gray-800 text-white">
            <div className="text-center">
              <h1 className="text-4xl font-bold">{data.name}</h1>
              <p className="text-xl mt-2">{data.title}</p>
            </div>
            <div className="mt-4 text-center">
              {data.email && <p>Email: {data.email}</p>}
              {data.phone && <p>Phone: {data.phone}</p>}
              {data.location && <p>Location: {data.location}</p>}
            </div>
          </div>

          <div className="p-8">
            {data.summary && (
              <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">Profile</h2>
                <p className="text-gray-700">{data.summary}</p>
              </section>
            )}

            {data.history.length > 0 && (
              <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">Employment History</h2>
                {data.history.map((item, index) => (
                  <div key={index} className="mt-4">
                    <div className="font-bold text-lg">{item.title}</div>
                    <p className="text-gray-600">{item.employer} | {moment(item.startDate).format('MMM YYYY')} - {moment(item.endDate).format('MMM YYYY')}</p>
                    <p className="mt-2 text-gray-700">{item.description}</p>
                  </div>
                ))}
              </section>
            )}

            {data.educations?.length > 0 && (
              <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">Education</h2>
                {data.educations.map((item, index) => (
                  <div key={index} className="mt-4">
                    <div className="font-bold text-lg">{item.degree}</div>
                    <p className="text-gray-600">{item.school}, {moment(item.startDate).format('MMM YYYY')} - {moment(item.endDate).format('MMM YYYY')}</p>
                    <p className="mt-2 text-gray-700">{item.description}</p>
                  </div>
                ))}
              </section>
            )}

            {data.skills?.length > 0 && (
              <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">Skills</h2>
                <div className="flex flex-wrap gap-2">
                  {data.skills.map((skill, index) => (
                    <span key={index} className="px-3 py-1 bg-gray-200 rounded-full text-gray-700">{skill.name}</span>
                  ))}
                </div>
              </section>
            )}

            {data.links?.length > 0 && (
              <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">Links</h2>
                <div className="flex flex-wrap gap-2">
                  {data.links.map((link, index) => (
                    <a key={index} href={link.url} className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">{link.name}</a>
                  ))}
                </div>
              </section>
            )}
          </div>
        </div>
      </div>
    </body>
  </html>
);

export default Template5;