import React from 'react';
import {Platform, View} from 'react-native';
import Form from './Form';
import {useMediaQuery} from 'react-responsive';
import Preview from './Preview';

export default function Home() {

  return (
    <View className="flex flex-1">
      <Form />

    </View>
  );
}
