import {useDispatch} from 'react-redux';
import {setForm} from '../slices/appSlice';

import {useCallback, useState} from 'react';
import WebView from 'react-native-webview';
import {useNavigation} from '@react-navigation/native';
import {Dimensions, Platform, View} from 'react-native';
import {useMediaQuery} from 'react-responsive';
import Preview from './Preview';
import { store } from '../slices/store';

const dims = Dimensions.get('window');



const Form = () => {
  const dispatch = useDispatch();
  const {navigate} = useNavigation();
  const isDesktop = useMediaQuery({
    query: '(min-width: 1224px)',
  });
  const [isPreview, setIsPreview] = useState(false);


  const onMessage = useCallback(event => {
    try {
      let {data} = event.nativeEvent;

      if (typeof data === 'string') {
        // alert(data)
        data = JSON.parse(data);
        console.log("data:", data)
        const name = data.name;
        // if (name !== 'form') return
        const payload = data.payload;
  
        switch (name) {
          case 'goToPreview': {
            navigate('Preview');
            break;
          }
          case 'form': {
            if (isDesktop) {
              setIsPreview(true);
  
              tsetform(payload)
              ;
            } else {
              dispatch(setForm(payload));
            }
          }
        }
      }
    } catch (err) {}

  }, []);

  return (
    <View className="flex flex-row flex-1 gap-4" style={{flexDirection: 'row'}}>
      <WebView
        source={
          __DEV__
            ? {
              uri: 'http://localhost:5173',
            }: Platform.OS === 'web'
            ? {
                uri: 'https://resume-creator-form.vercel.app',
              }
            : {uri: 'file:///android_asset/dist/index.html'}
        }
        javaScriptEnabled
        style={{
          flex: 1,
          opacity: 0.99,
          height: Platform.select({web: dims.height, default: undefined}),
        }}
        onMessage={onMessage}
        useWebView2
        originWhitelist={['*']}
        allowFileAccessFromFileURLs
        allowFileAccess
        webviewDebuggingEnabled={__DEV__}
        
      />

      {isPreview && isDesktop && Platform.OS === 'web' && <Preview />}
    </View>
  );
};

const throttleFunction=(func, delay)=>{
     
  // Previously called time of the function
  let prev = 0;
  return (...args) => {
    // Current called time of the function
    let now = new Date().getTime();
 
    // Logging the difference between previously
    // called and current called timings
    // console.log(now-prev, delay);
     
    // If difference is greater than delay call
    // the function again.
    if(now - prev> delay){
      prev = now;
 
      // "..." is the spread operator here
      // returning the function with the
      // array of arguments
      return func(...args); 
    }
  }
}

function debounce(func, timeout = 300){
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

const tsetform = debounce((payload) => {store.dispatch(setForm(payload)); console.log("updated")}, 500)
export default Form;
