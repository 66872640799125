import moment from 'moment';
import {sample} from '../pages/Form';

export const Template3 = ({data = sample}) => (
  <html>
    <head>
    <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com"  crossOrigin="anonymous" />
        <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet"  crossOrigin="anonymous" />
      <script src="https://cdn.tailwindcss.com"></script>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css"  crossOrigin="anonymous" />
      <meta name="viewport" content="width=100%"/>
      <style
        dangerouslySetInnerHTML={{
          __html: `
              body {
                font-family: 'Poppins', sans-serif;
                background-color: #fff;
                
              }
              html, body {
                width:  210mm;
                min-height: 297mm;
              }

              a {
                text-decoration: underline;
                
              }

              @page{
                size: A4;
                margin-left: 0px;
                margin-right: 0px;
                margin-top: 0px;
                margin-bottom: 0px;
              }

              ::-webkit-scrollbar {
                width: 0;
              }

              

    `,
        }}></style>
    </head>
    <body >
    <div className="w-full min-h-[100vh] flex flex-col items-center bg-white">
      <div className="w-full flex min-h-[100vh] text-gray-800 gap-12 ">

        {/* Left */}
        <div className="flex flex-1 flex-col p-8 min-h-full">
          <div className="flex flex-col gap-3">
            <span className="text-2xl font-extrabold">
              {[data.name, data.title].filter(Boolean).join(', ')}
            </span>
            <span className="text-xs text-gray-400">
              {[data.email, data.phone, data.location].filter(Boolean).join(', ')}
            </span>
          </div>

          {data.summary && <div className="flex flex-col mt-8 gap-2">
            
            <span className="text-lg font-bold text-gray-950 flex items-center gap-2">Profile</span>
            <span className="text-gray-600 text-sm" dangerouslySetInnerHTML={{__html: data.summary}} />
          </div>}

          {data.history.length > 0 && (
            <div className="flex flex-col mt-8 gap-3">
              <span className="text-lg font-bold text-gray-950 flex items-center gap-2">
                Employment History
              </span>
              {data.history.map((item, index) => (
                <div className="flex flex-col gap-2">
                  <span className="text-base font-semibold">
                    {[item.title, item.employer].filter(Boolean).join(', ')}
                  </span>
                  <span className="text-xs text-gray-400">
                    {[
                      `${moment(item.startDate).format(
                        'YYYY/MM/DD',
                      )} - ${moment(item.endDate).format('YYYY/MM/DD')}`,
                      item.location,
                    ].join(',')}
                  </span>
                  <span className="text-gray-600 text-sm whitespace-pre-line" dangerouslySetInnerHTML={{__html: item.description}} />
                </div>
              ))}
            </div>
          )}

          {data.educations?.length > 0 && (
            <div className="flex flex-col mt-8 gap-3">
              <span className="text-lg font-bold text-gray-950 flex items-center gap-2">
                Education
              </span>
              {data.educations.map((item, index) => (
                <div className="flex flex-col gap-2">
                  <span className="text-lg font-semibold">
                    {[item.degree, item.school].join(', ')}
                  </span>
                  <span className="text-xs text-gray-400">
                    {[
                      `${moment(item.startDate).format(
                        'YYYY/MM/DD',
                      )} - ${moment(item.endDate).format('YYYY/MM/DD')}`,
                      item.location,
                    ].join(',')}
                  </span>
                  <span className="text-gray-600 text-sm whitespace-pre-line" dangerouslySetInnerHTML={{__html: item.description}} />
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Right */}
        {(data.links.length === 0 && data.skills.length === 0) ? null : (
          <div className="flex flex-col min-h-[100vh] w-[250px] gap-7 bg-[#082a4d] text-gray-50 p-8">
            {(data.email || data.location || data.phone) && (
                <div className="flex flex-col gap-2">
                  <span className="text-sm mb-1 font-bold">Details</span>
                  {data.email && (
                    <span className="text-xs">
                      <a href={`mailto:${data.email}`}>{data.email}</a>
                    </span>
                  )}
                  {data.phone && (
                    <span className="text-xs">
                      <a href={`tel:${data.phone}`}>{data.phone}</a>
                    </span>
                  )}
                  {data.location && (
                    <span className="text-xs">
                      {data.location}
                    </span>
                  )}
                </div>
            )}           
            {data.links?.length > 0 && (
              <div className="flex flex-col gap-2">
                <span className="text-sm mb-1 font-bold">Links</span>
                {data.links.map((item, index) => (
                  <a href={item.url} className="text-xs" target='_blank'>
                    {item.name}
                  </a>
                ))}
              </div>
            )}

            {data.skills?.length > 0 && (
              <div className="flex flex-col gap-2">
                <span className="text-sm mb-1 font-bold">Skills</span>
                {data.skills.map((item, index) => (
                  <span className="text-xs">{item.name}</span>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
    </body>

  </html>
);
