import moment from 'moment';
import {sample, samplex} from '../pages/Form';

export const Template2 = ({data = samplex}) => (
  <html>
    <head>
      <link href="https://fonts.cdnfonts.com/css/avenir" rel="stylesheet" crossOrigin="anonymous" />
      <script src="https://cdn.tailwindcss.com"></script>
      <meta name="viewport" content="width=100%"/>
      <style
        dangerouslySetInnerHTML={{
          __html: `
                body {
                    font-family: 'Avenir', sans-serif;
                    background-color: #fff;
                }
                
                html, body {
                  width:  210mm;
                  height: 297mm;
                }

                
                @page {
                  size: A4;
                  margin: 24px;
                }

                ::-webkit-scrollbar {
                  width: 0;
                }
          `,
        }}
      />
    </head>

    <body >
    <div className=" w-full flex flex-col items-center bg-white min-h-[100vh]">
      <div className="flex w-full p-6 print:p-0 text-gray-800 flex-col ">
        <span className="text-[64px] font-extrabold">{data.name || 'Your Name'}</span>
        {data.title && <span className="text-lg font-bold">{data.title}</span>}
        {data.email && <span className="text-base font-medium text-gray-700">{data.email}</span>}
        {data.phone && <span className="text-base font-medium text-gray-700">{data.phone}</span>}
        {data.location && <span className="text-base font-medium text-gray-700">{data.location}</span>}
        {data.summary && <div className="p-4 rounded-3xl flex flex-col bg-gray-200 mt-4">
          <span className="text-[24px] font-extrabold">Profile</span>
          <span className="text-lg text-gray-600" dangerouslySetInnerHTML={{__html: data.summary}} />
        </div>}

        <div className="w-full h-[1px] bg-gray-200 my-6" />

        <div className="flex gap-6">
          <div className="flex flex-1 flex-col">
            {data.history.length > 0 && (
              <div className="flex flex-col mt-8 gap-3">
                <span className="text-[34px] font-bold text-gray-700">
                  Employment History
                </span>

                {data.history.map((item) => (
                  <div className="flex flex-col gap-2">
                    <span className="text-lg font-semibold bg-gray-800 text-white p-[2px] px-2">
                      {[item.employer, item.location].filter(Boolean).join(', ')}
                    </span>
                    <span className="text-base font-bold text-gray-700">
                      {`${moment(item.startDate).format(
                        'YYYY/MM/DD',
                      )} - ${moment(item.endDate).format('YYYY/MM/DD')}`}
                    </span>
                    <span className="text-gray-600 text-base font-medium whitespace-pre-line" dangerouslySetInnerHTML={{__html: item.description}} />
                  </div>
                ))}
              </div>
            )}

            {data.educations?.length > 0 && (
              <div className="flex flex-col mt-8 gap-3">
                <span className="text-[34px] font-bold text-gray-700">
                  Education
                </span>

                {data.educations.map((item) => (
                  <div className="flex flex-col gap-2">
                    <span className="text-lg font-semibold bg-gray-800 text-white p-[2px] px-2">
                      {[item.school, item.degree, item.location].join(', ')}
                    </span>
                    <span className="text-base font-bold text-gray-700">
                      {`${moment(item.startDate).format(
                        'YYYY/MM/DD',
                      )} - ${moment(item.endDate).format('YYYY/MM/DD')}`}
                    </span>
                    <span className="text-gray-600 text-base font-medium whitespace-pre-line" dangerouslySetInnerHTML={{__html: item.description}} />
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="flex flex-col min-w-[200px] gap-2 min-h-[100vh]">
            {data.links?.length > 0 && <div className="p-4 rounded-3xl flex flex-col w-full bg-gray-200 mt-4">
              <span className="text-[30px] font-extrabold mb-2">Links</span>
              {data.links.map(item => (
                <a href={item.url} className="text-base" target='_blank'>
                  {item.name}
                </a>
              ))}
            </div>}

            {data.skills?.length > 0 &&<div className="p-4 rounded-3xl flex flex-col w-full bg-gray-200 mt-4">
              <span className="text-[30px] font-extrabold mb-2">Skill</span>
              {data.skills.map(item => (
                <span className="text-base">{item.name}</span>
              ))}
            </div>}
          </div>
        </div>
      </div>
    </div>
    </body>
  </html>
);
