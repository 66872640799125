import React from 'react';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {NavigationContainer, } from '@react-navigation/native';
import Home from './screens/Home';
import Preview from './screens/Preview';

const Stack = createNativeStackNavigator();

const Navigator = () => {
  // const username = useSelector((state) => state.app.username)

  return (
    <NavigationContainer>
      <Stack.Navigator
        screenOptions={{
          headerShadowVisible: false,
          contentStyle: {height: '100vh'},
        
        }}>
        <Stack.Screen name="Home" component={Home} options={{ headerShown: false, title: 'Easy Resume'}} />
        <Stack.Screen name="Preview" component={Preview} options={{ headerShown: true, title: 'Preview'}} />
      </Stack.Navigator>
    </NavigationContainer>
  );
};


export default Navigator;
