import {createSlice} from '@reduxjs/toolkit';
import moment from 'moment';
import { initial } from '../form-web/src/pages/Form';

export interface CounterState {
  form: any;
}

const initialState: CounterState = {
  form: initial,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setForm: (state, action) => {
      state.form = {...state.form, ...action.payload};
    },
  },
});

// Action creators are generated for each case reducer function
export const {setForm} = appSlice.actions;

export const appReducer =  appSlice.reducer;
