import {AppRegistry} from 'react-native';
import mixpanel from 'mixpanel-browser';
import App from './App';
import './index.css'

mixpanel.init("b3dacc743335ef96435ee67a3caf85b3", { track_pageview: true, persistence: 'localStorage' });


AppRegistry.registerComponent('App', () => App);
AppRegistry.runApplication('App', {
  rootTag: document.getElementById('root'),
});