import WebView from 'react-native-webview';
import {renderToString} from 'react-dom/server';
import {useSelector} from 'react-redux';
import {useEffect, useMemo, useState} from 'react';
import {
  Text,
  View,
  Pressable,
  NativeModules,
  Image,
  ScrollView,
  Platform,
  Dimensions,
} from 'react-native';
const dims = Dimensions.get('window');
import 'text-encoding-polyfill';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {mixpanel} from '../utils/mixpanel';
import {Template1} from '../form-web/src/templates/Template1';
import {Template2} from '../form-web/src/templates/Template2';
import {Template3} from '../form-web/src/templates/Template3';
import {Template4} from '../form-web/src/templates/Template4';
import {Template5} from '../form-web/src/templates/Template5';
const RNHTMLtoPDF = NativeModules.RNHTMLtoPDF;
import {
  toPng,
  toJpeg,
  toBlob,
  toPixelData,
  toSvg,
  toCanvas,
} from 'html-to-image';
import {useMediaQuery} from 'react-responsive';
import {createPortal} from 'react-dom';
import html2canvas from 'html2canvas';
import {jsPDF} from 'jspdf';
const templates = {
  template1: {
    elem: Template1,
    img: require('../assets/templates/template1.png'),
  },
  template2: {
    elem: Template2,
    img: require('../assets/templates/template2.png'),
  },
  template3: {
    elem: Template3,
    img: require('../assets/templates/template3.png'),
  },
  template4: {
    elem: Template4,
    img: require('../assets/templates/template3.png'),
  },
  template5: {
    elem: Template5,
    img: require('../assets/templates/template3.png'),
  },
};

const Preview = () => {
  const form = useSelector(state => state.app.form);
  const [template, setTemplate] = useState<keyof typeof templates>('template1');
  const [previewImg, setPreviewImg] = useState(null);
  console.log('form', form);
  const isDesktop = useMediaQuery({
    query: '(min-width: 1224px)',
  });
  const Template = templates[template].elem;

  const html = useMemo(
    () => renderToString(<Template data={form} />),
    [form, Template],
  );

  useEffect(() => {
    AsyncStorage.getItem('template').then(value => {
      if (value) {
        setTemplate(value);
      }
    });
  }, []);

  useEffect(() => {
    AsyncStorage.setItem('template', template);

    if (Platform.OS === 'web') {
      var iframe = document.getElementById('preview');
      iframe.style.minHeight = '297mm';
    }
  }, [template]);

  useEffect(() => {
    if (Platform.OS === 'web') {
      // alert('fd')
      var iframe = document.getElementById('preview');
      iframe.addEventListener(
        'load',
        () => {
          iframe = document.getElementById('preview');

          // alert(iframe.contentWindow.document.body.scrollHeight)
          iframe.style.minHeight =
            iframe.contentWindow.document.body.scrollHeight + 'px';
          // iframe.height = iframe.contentWindow.document.body.scrollHeight
          if (!isDesktop) {
            toSvg(iframe, {
              height: iframe.contentWindow.document.body.scrollHeight,
            }).then(function (dataUrl) {
              console.log('dataUrl:', dataUrl);
              setPreviewImg(dataUrl);
            });
          }
          let previewContainer = document.getElementById('preview-container');
          previewContainer.scrollTo({
            top: 0,
          });
        },
        true,
      );
    }
  }, []);

  useEffect(() => {}, [html]);

  if (!Template) return null;

  const createPDF = async () => {
    if (Platform.OS === 'web') {
      var iframe = document.getElementById('preview');
      var iframeWindow = iframe.contentWindow;
      console.log('iframeWindow:', iframeWindow);
      var printWindow = window.open('', '_blank', 'height=768,width=1024');
      console.log('outerHTML', iframeWindow.document.documentElement.outerHTML);

      printWindow.document.write(
        iframeWindow.document.documentElement.outerHTML,
      );
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
    } else {
      try {
        let options = {
          html,
          fileName: 'test',
          height: 842,
          width: 595,
        };

        let file = await RNHTMLtoPDF.convert(options);
        mixpanel.track('export-pdf-selected');
        // alert(file.filePath);
      } catch (err) {
        alert(err);
      }
    }
  };

  if (!form) return null;

  const webview = (
    <WebView
      id="preview"
      source={{
        html,
      }}
      javaScriptEnabled
      style={{

        ...Platform.select({
          web: {
            minHeight: '297mm',
            // maxHeight: '297mm',
            width: '210mm',
            boxShadow: '#ccc 0px 7px 29px 0px'
          },
          default: {
            flex: 1,
          },
        }),
      }}
      {...Platform.select({
        web: {
          width: '210mm',
          // height:"100%",
          scrolling: 'no',
          frameBorder: '0',
        },
        default: null,
      })}
    />
  );

  return (
    <View style={{ minWidth: '55%', alignItems: 'center'}}>
      <View
        style={{
          ...(Platform.OS === 'web'
            ? {
                overflowY: 'auto',
                overflowX: 'hidden',
                maxHeight: dims.height * (71 / 100) - (isDesktop ? 0 : 60),
                minHeight: dims.height * (71 / 100) - (isDesktop ? 0 : 60),
                height: dims.height * (71 / 100) - (isDesktop ? 0 : 60),
              }
            : {
              flex: 1,
              width: dims.width
            }),
        }}
        id="preview-container">
        {isDesktop || Platform.OS !== 'web' ? (
          webview
        ) : (
          <div style={{width: 0, height: 0, overflow: 'hidden'}}>
            {webview}
          </div>
        )}
        {!isDesktop && Platform.OS === 'web' && (
          <img
            id="preview-img"
            src={previewImg}
            style={{
              // height: Platform.select({web: '297mm', default: undefined}),
              // maxWidth: isDesktop ? '210mm' : dims.width,
              width: '100%',
            }}
          />
        )}

        {/* <div
        id="preview-canvas"
        style={{
          // height: Platform.select({web: '297mm', default: undefined}),
          // maxWidth: isDesktop ? '210mm' : dims.width,
          width: '100%',  
          zIndex: 1,
        }}
      /> */}
      </View>

      <View
        style={{
          width: '100%',
          borderTopWidth: 1,
          borderColor: '#b4bcca',
          paddingHorizontal: 12,
          paddingVertical: 8,
          height: '100%',
          maxHeight: dims.height * (29 / 100),
        }}>
        <Text style={{color: '#374151', fontWeight: '500', marginBottom: 12}}>
          Select Template
        </Text>
        <ScrollView horizontal style={{marginBottom: 16}}>
          {Object.entries(templates).map(([key, {elem, img}]) => (
            <Pressable
              key={key}
              onPress={() => setTemplate(key as any)}
              style={{
                borderColor: key === template ? '#3b82f6' : 'transparent',
                borderWidth: 2,
                marginRight: 12,
              }}>
              <Image
                source={img}
                // className=" h-[125px] w-[93px]"
                style={{height: '100%', width: 93}}
                resizeMode="cover"
              />
            </Pressable>
          ))}
        </ScrollView>

        <Pressable
          onPress={createPDF}
          style={{
            backgroundColor: '#3b82f6',
            padding: 12,
            paddingHorizontal: 36,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 4,
            alignSelf: 'flex-start',
            ...Platform.select({web: {cursor: 'pointer'}, default: {width: '100%'}}),
          }}>
          <Text style={{color: '#fff', fontSize: 16}}>Export Resume</Text>
        </Pressable>
      </View>
    </View>
  );
};

export default Preview;
