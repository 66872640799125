import moment from 'moment';
import {sample} from '../pages/Form';

export const Template1 = ({data = sample}) => (
  <html>
    <head>
      <link href="https://fonts.cdnfonts.com/css/avenir" rel="stylesheet" crossOrigin="anonymous" />
      <script src="https://cdn.tailwindcss.com"></script>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css" crossOrigin="anonymous" />
      <meta name="viewport" content="width=100%"/>
      <style
        dangerouslySetInnerHTML={{
          __html: `
              body {
                font-family: 'Avenir', sans-serif;
                background-color: #fff;
                
              }
              html, body {
                width:  210mm;
                height: 297mm;
              }

              @page{
                size: A4;
                margin: 32px;
              }

              ::-webkit-scrollbar {
                width: 0;
              }

    `,
        }}></style>
    </head>
    <body >
    <div className="w-full flex flex-col items-center bg-white min-h-[100vh]">
      <div className="w-full flex p-8 print:p-0 text-gray-800 gap-12 ">
        <div className="flex flex-1 flex-col">
          <div className="flex flex-col gap-3">
            <span className="text-2xl font-extrabold">
              {[data.name, data.title].filter(Boolean).join(', ')}
            </span>
            <span className="text-xs text-gray-400">
              {[data.email, data.phone, data.location].filter(Boolean).join(', ')}
            </span>
          </div>

          {data.summary && <div className="flex flex-col mt-8 gap-2">
            
            <span className="text-lg font-light text-gray-700 flex items-center gap-2"><i className="fa-solid fa-user text-[16px]"></i>PROFILE</span>
            <span className="text-gray-600 text-sm" dangerouslySetInnerHTML={{__html: data.summary}} />
          </div>}

          {data.history.length > 0 && (
            <div className="flex flex-col mt-8 gap-3">
              <span className="text-lg font-light text-gray-700 flex items-center gap-2">
                <i className="fa-solid fa-briefcase text-[16px]"></i>EMPLOYMENT HISTORY
              </span>
              {data.history.map((item, index) => (
                <div className="flex flex-col gap-2">
                  <span className="text-lg font-semibold">
                    {[item.title, item.employer].filter(Boolean).join(', ')}
                  </span>
                  <span className="text-xs text-gray-400">
                    {[
                      `${moment(item.startDate).format(
                        'YYYY/MM/DD',
                      )} - ${moment(item.endDate).format('YYYY/MM/DD')}`,
                      item.location,
                    ].join(',')}
                  </span>
                  <span className="text-gray-600 text-sm whitespace-pre-line" dangerouslySetInnerHTML={{__html: item.description}} />
                </div>
              ))}
            </div>
          )}

          {data.educations?.length > 0 && (
            <div className="flex flex-col mt-8 gap-3">
              <span className="text-lg font-light text-gray-700 flex items-center gap-2">
                <i className="fa-solid fa-school text-[16px]"></i>
                EDUCATION
              </span>
              {data.educations.map((item, index) => (
                <div className="flex flex-col gap-2">
                  <span className="text-lg font-semibold">
                    {[item.degree, item.school].join(', ')}
                  </span>
                  <span className="text-xs text-gray-400">
                    {[
                      `${moment(item.startDate).format(
                        'YYYY/MM/DD',
                      )} - ${moment(item.endDate).format('YYYY/MM/DD')}`,
                      item.location,
                    ].join(',')}
                  </span>
                  <span className="text-gray-600 text-sm whitespace-pre-line" dangerouslySetInnerHTML={{__html: item.description}} />
                </div>
              ))}
            </div>
          )}
        </div>

        {(data.links.length === 0 && data.skills.length === 0) ? null : (
          <div className="flex flex-col w-[110px] gap-2">
            {data.links?.length > 0 && (
              <div className="flex flex-col gap-2">
                <span className="text-md mb-2 font-medium">LINKS</span>
                {data.links.map((item, index) => (
                  <a href={item.url} className="text-base" target='_blank'>
                    {item.name}
                  </a>
                ))}
              </div>
            )}

            {data.skills?.length > 0 && (
              <div className="flex flex-col gap-2 mt-4">
                <span className="text-md mb-2 font-medium">SKILLS</span>
                {data.skills.map((item, index) => (
                  <span className="text-base">{item.name}</span>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
    </body>
  </html>
);
